@use '@styles/mixins/_breakpoints' as breakpoints;

.wrapper {
  border: 1px solid var(--ultraLightGray);
  box-shadow: 0 6px 12px 0px rgba(96, 97, 112, 0.16);
  @include breakpoints.down(md) {
    height: 25em;
  }
}

.footerWrapper {
  align-items: flex-start;
  display: flex;
  height: auto;
}

.productName {
  text-transform: uppercase;

  &[product-type='contact-lenses'] {
    font-size: 0.75rem;
    line-height: 1.33;

    @include breakpoints.up(md) {
      font-size: 0.875rem;
    }
  }
}
