@use '@styles/mixins/_breakpoints' as breakpoints;

.wrapper {
  padding-top: 0;
  padding-bottom: 16px;
  @include breakpoints.up(md) {
    padding-top: 12px;
  }
}

.cmsSwiper {
  :global(.swiper-wrapper) {
    margin-bottom: 16px;

    @include breakpoints.up(md) {
      margin-bottom: 32px;
    }
  }

  :global(.swiper-pagination) {
    width: calc(100% - 32px);
  }
}
