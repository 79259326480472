@use '@styles/mixins/_breakpoints.scss' as breakpoints;
@use '@styles/functions' as functions;

.promo-strip-wrapper {
    display: flex;
    overflow: hidden;
    align-items: center;
    min-height: 80px;
    padding-bottom: unset;
    margin: functions.spacing(0, 4, 0, 4);

    [class*="media-wrapper"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -999;
    }


    .default {
        text-align: left;
    }

    .promo-strip-icon {
        margin: functions.spacing(auto, 6, auto, 6);

        @include breakpoints.down(sm) {
            font-size: 1.25rem;
            margin: functions.spacing(auto, 4, auto, 4);
        }
    }


    .promo-strip-cta {
        a {
            margin: functions.spacing(2);
        }
    }

    .promo-strip-contents {
        margin: auto 0;
    }

    .right {
        text-align: right;
    }

    .center {
        text-align: center;
    }

    .left {
        text-align: left;
    }

    p {
        margin: unset;
    }

    .body {
        font-weight: 900;
    }

    .title {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: 0%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Mulish;


        @include breakpoints.between(xs, md) {
            font-size: 1.25rem;
        }
    }
}

.container {
    margin: functions.spacing(0, 6, 0, 6);

    @include breakpoints.between(xs, md) {
        margin: 0;
    }
}

.countdown {
    color: black;
    font-weight: 900;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: 0%;

    @include breakpoints.between(xs, md) {
        margin: 0;
        font-size: 2rem;
    }
}

.block-left-middle {
    justify-content: start;

    @include breakpoints.between(xs, md) {
        align-items: start;
    }
}

.block-center-middle {
    justify-content: center;

    @include breakpoints.between(xs, md) {
        align-items: center;
    }
}

.block-right-middle {
    justify-content: end;
    flex-direction: row-reverse;

    @include breakpoints.between(xs, md) {
        align-items: end;
    }
}

.direction-column {
    @include breakpoints.between(xs, md) {
        flex-direction: column;
    }
}