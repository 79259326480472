@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.heroBanner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: fit-content;
  gap: 0;

  @include breakpoints.up(769px) {
    height: functions.spacing(104);
  }

  @include breakpoints.up(1281px) {
    height: functions.spacing(117);
  }

  @include breakpoints.down(md) {
    height: 500px;
    padding: 0;
  }

  [class*='cmsMediaLink'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    height: 100%;
  }
  @include breakpoints.up(md) {
    :global(.block-left-middle) {
      margin-right: auto;
      margin-left: 5rem;
    }

    :global(.block-center-middle) {
      margin-left: auto;
      margin-right: auto;
    }

    :global(.block-right-middle) {
      margin-left: auto;
      margin-right: 5rem;
    }
  }
  @include breakpoints.down(md) {
    section[class*='left'],
    section[class*='right'] {
      align-items: center;
      text-align: center;
    }
  }

  :global(.text-module) {
    padding: functions.spacing(8, 4, 0);
    position: relative;
    width: 100%;

    div[class*='teaser-pretitle'] {
      font-size: 15px;
    }

    span[class*='countdown'] {
      font-size: 40px;
    }

    p {
      font-size: 0.75rem;
    }

    @include breakpoints.up(md) {
      max-width: 650px;
      padding: 0;
      width: auto;

      h2 {
        font-size: 2rem;
        line-height: 1.25;
      }

      div[class*='terms-and-conditions'] {
        position: fixed;
        bottom: 32px;
        right: 24px;

        div[class*='toast'] {
          left: 24px;
          padding: 0;

          h4 {
            margin-top: 16px;
          }

          button {
            top: 0;
            margin: 8px;
          }

          p {
            margin: 16px;
          }
        }

        button {
          font-size: 0.94rem;
        }
      }
    }

    div[class*='cta-container'] {
      margin-top: 0;

      a,
      button {
        margin-inline: 0;
      }
    }

    @include breakpoints.down(md) {
      div[class*='cta-container'] {
        align-items: center;
      }
    }

    @include breakpoints.down(md) {
      margin: auto 0 32px;
      padding: 0;
      padding-bottom: 20px;
      gap: 16px;

      h2 {
        font-size: 1.25rem;
      }
      div[class*='teaser-text'] {
        div > p {
          padding-inline: 32px;
        }
      }

      div[class*='cta-container'] a,
      div[class*='cta-container'] button {
        margin: 0;
        margin-inline: auto;
        min-width: 295px;
      }

      div[class*='terms-and-conditions'] {
        div[class*='toast'] {
          left: 0;
          min-height: 100px;
          padding: 0;

          button {
            top: 0;
            margin: 8px;
          }

          p {
            margin-inline: 16px;
          }
        }
      }
    }
  }

  div[class*='swiper-pagination-horizontal'] {
    margin: auto;
    height: 4px;
    bottom: 40px;
  }

  span[class='swiper-pagination-bullet'] {
    height: 5px;
    width: 22px;
    border-radius: 0;
    transform: scale(1);
    margin: 0 !important;
    background-color: var(--white);
    opacity: 1;
  }

  span[class='swiper-pagination-bullet']:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  span[class='swiper-pagination-bullet']:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  span[class*='swiper-pagination-bullet-active'] {
    height: 5px;
    width: 22px;
    border-radius: 10px;
    margin: 0 !important;
    background-color: var(--black);
  }

  div[class*='swiper-button-prev'],
  div[class*='swiper-button-next'] {
    color: var(--boulder);
    margin-top: 0;
    &::after {
      font-size: 1.5rem;
      font-weight: 900;
      text-shadow: 2px 2px 4px var(--boulder);
    }
  }
  @include breakpoints.up(md) {
    div[class*='swiper-button-prev'],
    div[class*='swiper-button-next'] {
      padding-bottom: 32px;
    }
  }
}
