@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/mixins/teaser-overlay' as teaser-overlay;

.boardContentWrapper {
  display: flex;
  flex-direction: column;

  @include breakpoints.up(sm) {
    @include teaser-overlay.overlay-settings-to-vertical-css();
  }

  &.left > * {
    text-align: left;
  }

  &.center > * {
    text-align: center;
  }

  &.right > * {
    text-align: right;
  }

  &.text-dark-primary > * {
    color: var(--text-primary-dark);
  }

  &.text-dark-secondary > * {
    color: var(--text-primary-dark);
  }

  &.text-light-primary > * {
    color: var(--text-primary-light);
  }

  &.text-light-secondary > * {
    color: var(--boulder);
  }
}

.titleContainer {
  line-height: 1.33;
  text-transform: none;
}

.titleArticle {
  width: 100%;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.ctaContainer {
  display: flex;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
  gap: 4px;
  flex-direction: column;

  @include breakpoints.up(md) {
    gap: 8px;
    flex-direction: row;
  }
}

.contentTextModule {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 12px;

  @include breakpoints.up(md) {
    padding-block: 24px;
  }

  @include breakpoints.up(lg) {
    padding: 32px 24px;
  }
}

.noTopPadding {
  padding-top: 0;
}
