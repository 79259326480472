@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

@mixin defaultTextModuleStyle {
  color: var(--text-primary-dark);
  width: 100%;
  padding: functions.spacing(4);

  &.text-dark-primary {
    color: var(--text-primary-dark);
  }
  &.text-dark-secondary {
    color: var(--text-primary-dark);
  }
  &.text-light-secondary {
    color: var(--darkGray);
  }

  @include breakpoints.up(0) {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @include breakpoints.up(769px) {
    height: 100%;
    padding-top: 0;

    &.text-light-primary {
      color: var(--text-primary-light);
    }
  }
  @include breakpoints.up(lg) {
    padding-top: functions.spacing(15);
  }
  @include breakpoints.up(1025px) {
    padding-top: functions.spacing(10);
  }
  @include breakpoints.up(1281px) {
    padding-top: functions.spacing(10);
  }
  @include breakpoints.down(769px) {
    padding-left: functions.spacing(4);
    padding-right: functions.spacing(4);
  }
  article {
    justify-content: center;
  }
}

.textModuleWrapper {
  @include defaultTextModuleStyle();

  pointer-events: auto;
  position: static;
  z-index: unset;

  @include breakpoints.up(1025px) {
    left: unset;
    top: unset;
  }
}

.textModuleWrapperWithOverlay {
  @include defaultTextModuleStyle();

  @include breakpoints.up(769px) {
    pointer-events: none;
    position: absolute;
  }
}

.block-left-top,
.block-left-middle,
.block-left-bottom,
.block-center-top,
.block-center-middle,
.block-center-bottom,
.block-right-top,
.block-right-middle,
.block-right-bottom {
  margin: functions.spacing(0, 16, 0, 0);
  padding-bottom: unset;
}

.block-left-top {
  bottom: unset;
  left: 0;
  top: 0;
}

.block-left-middle {
  bottom: 0;
  left: 0;
  top: 0;
}

.block-left-bottom {
  bottom: 0;
  left: 0;
}

.block-center-top {
  bottom: unset;
  left: calc(32% - 64px);
  top: 0;
}

.block-center-middle {
  bottom: unset;
  left: 0;
  top: unset;
}

.block-center-bottom {
  bottom: 0;
  left: calc(32% - 64px);
}

.block-right-top {
  bottom: unset;
  right: 0;
  top: 0;
}

.block-right-middle {
  bottom: unset;
  right: 0;
  top: unset;
}

.block-right-bottom {
  bottom: 0;
  right: 0;
}

.gridContainerNoGap {
  column-gap: 0;
  row-gap: 0;
}

@include breakpoints.down(md) {
  .ctaContainer {
    width: 100%;
  }
}
