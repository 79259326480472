@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.landscapeBanner {
  overflow: hidden;
  padding: functions.spacing(6, 0);
  position: relative;
  width: 100%;
  height: fit-content;

  @include breakpoints.up(769px) {
    height: functions.spacing(104);
  }

  @include breakpoints.up(1281px) {
    height: functions.spacing(117);
  }

  @include breakpoints.down(lg) {
    padding: functions.spacing(4, 0);

    &:global(.has-terms) {
      padding-bottom: functions.spacing(14);
    }
  }

  [class*="cmsMediaLink"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    height: 100%;
  }

  :global(.block-left-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-left: 5rem;
    }
  }

  :global(.block-center-middle) {
    margin-left: auto;
    margin-right: auto;
  }

  :global(.block-right-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-right: 5rem;
    }
  }

  :global(.text-module) {
    padding: functions.spacing(8, 6, 0);
    position: relative;
    width: 100%;

    @include breakpoints.up(lg) {
      max-width: 650px;
      padding: 0;
      width: auto;
    }
  }
}

.landscapeBannerVideoWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.landscapeBannerTermsConditions {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 1;
  bottom: 0;

  @include breakpoints.up(lg) {
    padding-right: functions.spacing(6);
  }

  button {
    margin-bottom: functions.spacing(6);
  }

  & > :global(.toast) button {
    right: functions.spacing(6);
  }
}
