@use '@styles/functions' as functions;

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(2);
  margin: functions.spacing(2) 0;
  text-align: center;
}

.errorTitle {
  font-weight: 700;
}

.errorDescription {
  font-size: 0.875rem;
}
