@mixin overlay-settings {
  &.block-left-top {
    left: 0;
    top: 0;
    bottom: unset;
    padding: unset;
  }

  &.block-left-middle {
    left: 0;
    bottom: 0;
    top: 0;
  }

  &.block-left-bottom {
    left: 0;
    bottom: 0;
    padding: unset;
  }

  &.block-center-top {
    left: 50%;
    bottom: unset;
    padding: unset;
    top: 0;
  }

  &.block-center-middle {
    left: 0;
    bottom: unset;
    padding: unset;
    top: unset;
  }

  &.block-center-bottom {
    left: 50%;
    bottom: 0;
    padding: unset;
  }

  &.block-right-top {
    bottom: unset;
    padding: unset;
    right: 0;
    top: 0;
  }

  &.block-right-middle {
    bottom: unset;
    padding: unset;
    right: 0;
    top: unset;
  }

  &.block-right-bottom {
    bottom: 0;
    padding: unset;
    right: 0;
  }
}

@mixin overlay-settings-to-vertical-css() {
  justify-content: center;

  &.block-left-top,
  &.block-center-top,
  &.block-right-top {
    justify-content: flex-start;
  }

  &.block-left-middle,
  &.block-center-middle,
  &.block-right-middle {
    justify-content: center;
  }

  &.block-left-bottom,
  &.block-center-bottom,
  &.block-right-bottom {
    justify-content: flex-end;
  }
}
