@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.gridContainer {
  align-self: auto;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: functions.spacing(4);
  grid-row-gap: functions.spacing(4);
  grid-column: span 4;
  padding-bottom: auto;
  padding-top: auto;

  @include breakpoints.up(600px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: functions.spacing(8);
    grid-row-gap: functions.spacing(8);
    grid-column: span 12;
    padding: 0;
  }

  @include breakpoints.up(1025px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: functions.spacing(8);
    grid-row-gap: functions.spacing(8);
    grid-column: span 12;
    padding: 0;
  }
}

.noVertPadding {
  align-self: normal;
}

.container {
  @include breakpoints.up(md) {
    padding: functions.spacing(4);

    &.noVertPadding {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  @include breakpoints.up(1025px) {
    padding: functions.spacing(16);

    &.noVertPadding {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}
