@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.topPageBanner {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: functions.spacing(71);

  @include breakpoints.up(769px) {
    height: functions.spacing(116);
  }

  @include breakpoints.up(1025px) {
    height: functions.spacing(80);
  }

  @include breakpoints.up(lg) {
    padding-bottom: 0;
  }

  [class*="cmsMediaLink"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    height: 100%;
  }

  :global(.block-left-middle) {
    margin-left: 5rem;

    @include mxs.setElementPosition($selector: '.text-module',
      $top: functions.spacing(6),
      $left: 50%,
      $transform: translate(-50%, 0));
  }

  :global(.block-center-middle) {
    margin-left: auto;
    margin-right: auto;
  }

  :global(.block-right-middle) {
    margin-left: auto;
    margin-right: 9.375rem;
  }

  :global(.text-module) {
    position: relative;
    width: 100%;

    @include breakpoints.down(lg) {
      margin: 0;
    }

    @include breakpoints.up(lg) {
      max-width: 650px;
      width: auto;
    }
  }
}

.topPageBannerTextModuleWrapper {
  padding: functions.spacing(2, 4, 10);

  @include breakpoints.up(lg) {
    padding: functions.spacing(2, 4);
  }

  @include breakpoints.down(lg) {
    :global(.terms-and-conditions) {
      text-align: center;
      width: 100%;
    }
  }
}

.topPageBannerVideoWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.topPageBannerTermsConditions {
  display: none;

  @include breakpoints.up(lg) {
    display: block;
    z-index: 1;

    button {
      position: absolute;
      bottom: functions.spacing(4.5);
      right: functions.spacing(6);
    }
  }
}