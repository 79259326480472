@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.wrapper {
  display: flex;
  flex-direction: column;
}

.gridContainerNoGap {
  &:global(.grid-container) {
    column-gap: 0;
    row-gap: 0;
  }

  & :nth-child(2) {
    & :global(.terms-and-conditions) {
      margin-bottom: 0;
    }
  }
}

.termsAndConditions {
  margin: functions.spacing(0, 0, 4, 0);

  @include breakpoints.up(769px) {
    margin: functions.spacing(3, 0, 0, 0);

    button {
      margin: functions.spacing(0, 6, 4, 0);
    }
  }
}

@include breakpoints.up(1025px) {
  .gridItem {
    max-height: 652px;
  }
}
