@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.container {
  font-size: 1rem;

  p {
    margin: 0;
  }

  a {
    color: var(--text-primary-dark);
    font-weight: 600;
  }

  @include breakpoints.up(md) {
    .align--left,
    & .align--left {
      text-align: left;
    }

    .align--center,
    & .align--center {
      text-align: center;
    }

    .align--right,
    & .align--right {
      text-align: right;
    }

    .align--justify,
    & .align--justify {
      text-align: justify;
    }

    & .left {
      text-align: left;
    }

    & .center {
      text-align: center;
    }

    & .right {
      text-align: right;
    }

    & .justify {
      text-align: justify;
    }
  }

  h1 {
    font-size: 3.75rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  .text-small {
    font-size: 0.75rem;
  }

  .text-large {
    font-size: 1.25rem;
    line-height: 1.7;

    @include breakpoints.up(sm) {
      font-size: 2.5rem;
      line-height: 1.4;
    }
  }

  table {
    border-collapse: collapse;
    display: flex;
    font-size: 0.75rem;
    justify-content: left;

    tr,
    td {
      border: 2px solid var(--text-secondary-dark);
      text-align: left;
      padding: functions.spacing(2);
    }

    tr:first-of-type {
      font-weight: bold;
      background-color: var(--background-secondary-light);
    }
  }

  table.center {
    justify-content: center;
  }

  table.left {
    justify-content: left;
  }

  table.right {
    justify-content: right;
  }
}
