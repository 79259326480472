@use '@styles/functions' as functions;

.container {
  display: flex;
}

.icon {
  flex: 0 1 auto;
  height: functions.spacing(12);
  margin-bottom: functions.spacing(9);
  margin-top: 0;
  width: functions.spacing(12);

  &.landscape {
    margin-bottom: functions.spacing(4);
    margin-top: functions.spacing(4);
  }
}
