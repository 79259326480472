@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.squatBanner {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 15rem;

  @include breakpoints.down(md) {
    min-height: 375px;
    margin: auto;
    width: 375px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 16px 24px 16px;
  }

  @include breakpoints.down(lg) {

    &:global(.has-terms) {
      padding-bottom: 3.5rem;
    }
  }

  [class*="cmsMediaLink"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    height: 100%;
  }

  :global(.block-left-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-left: 5rem;
    }
  }

  :global(.block-center-middle) {
    margin-left: auto;
    margin-right: auto;
  }

  :global(.block-right-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-right: 5rem;
    }
  }

  :global(.text-module) {
    padding: 0;
    position: relative;
    width: 100%;
    gap: 0;

    & > div > div:first-child {
      font-size: 1.125rem;
    }
    @include breakpoints.down(md) {
      margin-top: 48px;
      h2 {
        font-size: 1.25rem;
        margin: 0;
        margin-bottom: 0.5rem;
        line-height: 25px;
      }
      & > div > div:first-child {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      div[class*="cta-container"] {
        margin-top: 0.5rem;
        button, a {
          margin: 0;
          padding: 9px 24px;
        }
      }
    }

    @include breakpoints.up(lg) {
      max-width: 650px;
      width: auto;
    }
  }
}

.squatBannerVideoWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.squatBannerTermsConditions {
  display: flex;
  width: 100%;
  z-index: 1;

  @include breakpoints.up(769) {
    padding-right: functions.spacing(6);
  }
  @include breakpoints.down(md) {
    margin-top: 0.75rem;
    button {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 700;
      padding: 4px;
    }
  }

  &> :global(.toast) button {
    right: functions.spacing(6);
  }
}