@use '@styles/mixins/breakpoints' as breakpoints;

.gridItem {
  position: relative;
  width: 100%;
}

.grid-sm-12 {
  grid-column: span 12;
}
.grid-sm-8 {
  grid-column: span 8;
}
.grid-sm-6 {
  grid-column: span 6;
}
.grid-sm-2 {
  grid-column: span 2;
}

@include breakpoints.up(769px) {
  .grid-md-12 {
    grid-column: span 12;
  }
  .grid-md-8 {
    grid-column: span 8;
  }
  .grid-md-6 {
    grid-column: span 6;
  }
  .grid-md-3 {
    grid-column: span 3;
  }
}

@include breakpoints.up(lg) {
  .grid-lg-12 {
    grid-column: span 12;
  }
  .grid-lg-8 {
    grid-column: span 8;
  }
  .grid-lg-6 {
    grid-column: span 6;
  }
  .grid-lg-3 {
    grid-column: span 3;
  }
}

@include breakpoints.up(xl) {
  .grid-xl-12 {
    grid-column: span 12;
  }
  .grid-xl-8 {
    grid-column: span 8;
  }
  .grid-xl-6 {
    grid-column: span 6;
  }
  .grid-xl-3 {
    grid-column: span 6;
  }
}

@include breakpoints.up(xxl) {
  .grid-xxl-12 {
    grid-column: span 12;
  }
  .grid-xxl-8 {
    grid-column: span 8;
  }
  .grid-xxl-6 {
    grid-column: span 6;
  }
  .grid-xxl-4 {
    grid-column: span 4;
  }
}
