@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/mixins/teaser-overlay' as teaser-overlay;
@use '@styles/_variables.scss' as variables;

.wrapper {
  height: 100%;
  position: static;
  top: 0;
  width: 100%;
  word-break: break-word;
  z-index: unset;

  @include breakpoints.up(lg) {
    display: flex;
    @include teaser-overlay.overlay-settings();
  }

  &.isTextOverlay {
    position: absolute;
    z-index: variables.$z-index-lv1;
  }
}
